import PropTypes from "prop-types"
import React from "react"
import cssVars from "css-vars-ponyfill"
import { Helmet } from "react-helmet"

import Header from "./organisms/header/header"
import Footer from "./organisms/footer/footer"
import CookieConsent from "./molecules/cookieConsent/CookieConsent"

import "../styles/fonts.css"
import "../styles/variables.css"
import "../styles/global.css"
import styles from "./layout.module.css"

const Layout = ({ children, logoText }) => (
  <div className={styles.layout}>
    <Helmet>
      <script>
        {cssVars({
          /* Fixes for IE11 CSS custom properties */
        })}
      </script>
    </Helmet>
    <Header logoText={logoText}>
      <div className={styles.headerItem}>
        <span className={styles.headerItemLabel}></span>
      </div>
      <div className={styles.headerItem}>
        <span className={styles.headerItemLabel}>Fragen?</span>{" "}
        <a href="mailto:neukunden@obeta.de">neukunden@obeta.de</a>
      </div>
      <div className={styles.headerItem}>
        <span className={styles.headerItemLabel}>T</span>{" "}
        <a href="tel:+49-30-720-94-550">+49 30 72094-550</a>
      </div>
    </Header>
    <main className={styles.main}>{children}</main>
    <Footer />
    <CookieConsent />
  </div>
)

Layout.propTypes = {
  logoText: PropTypes.string,
}

Layout.defaultProps = {
  logoText: "Häusler",
}

export default Layout
